import { graphqlEndpoint, queryWorksEntries, queryWorksFeature } from '../utils/graphql';

export default () => ({
	searchTerm: '',
	isLoading: true,
	hasError: false,
	data: [],
	dataFiltered: [],
	open: false,
	categories: [],
	selectedCategory: '',
	limitCards: 6,
	featureWork: [],
	showAll: false,
	lang: document.querySelector('html').getAttribute('lang') || 'en',
	/**
	 * Initializes the component.
	 *
	 * Fetches the data and filters the categories.
	 * Watches for changes in the search term and the showAll flag.
	 */
	async init() {
		const url = new URL(window.location.href);

		await this.fetchData();
		this.getCategories();

		this.searchTerm = url.searchParams.get('searchTerm') ?? '';
		this.showAll = Boolean(url.searchParams.get('showAll')) ?? false;
		this.selectedCategory = url.searchParams.get('selectedCategory') ?? '';
		this.searchFilter();
		this.updateUrl();

		this.$watch('searchTerm', () => this.searchTermCallback());
		this.$watch('showAll', (value) => this.showAllCards(value));
		this.$watch('selectedCategory', () => {
			this.searchFilter();
			this.updateUrl();
		});
	},
	updateUrl() {
		const url = new URL(window.location.href);
		url.searchParams.set('searchTerm', this.searchTerm);
		url.searchParams.set('showAll', this.showAll);
		url.searchParams.set('selectedCategory', this.selectedCategory);
		window.history.replaceState({}, '', url.href);
	},
	/**
	 * Fetches the data from the GraphQL endpoint.
	 *
	 * Fetches the works feature and the works entries.
	 * If the feature work is found, it filters the entries so that the feature work is not included.
	 * If the data is successfully fetched, it sets the `data` property with the filtered entries.
	 * If there are errors, it sets the `hasError` property to true.
	 * Finally, it sets the `isLoading` property to false.
	 */
	async fetchData() {
		try {
			const response = await fetch(graphqlEndpoint, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					query: queryWorksFeature,
					variables: {
						siteHandle: this.lang === 'fr' ? 'francais' : 'english'
					}
				})
			});

			const result = await response.json();

			if (result.errors) {
				console.error('GraphQL errors:', result.errors);
				this.hasError = true;
			} else {
				if (result.data.entries.length > 0) {
					this.featureWork = result.data.entries[0];
				}
			}
		} catch (error) {
			console.error('Error fetching data:', error);
			this.hasError = true;
		}

		try {
			const response = await fetch(graphqlEndpoint, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					query: queryWorksEntries,
					variables: {
						siteHandle: this.lang === 'fr' ? 'francais' : 'english'
					}
				})
			});

			const result = await response.json();
			console.log('result:', result);

			if (result.errors) {
				console.error('GraphQL errors:', result.errors);
				this.hasError = true;
			} else {
				if (this.featureWork && this.featureWork.length > 0) {
					this.data = result.data.entries.filter((item) => {
						return item.id !== this.featureWork.article[0].id;
					});
				} else {
					this.data = result.data.entries;
				}

				this.updateDateLocale(this.data);
				this.updateDateLocale(this.featureWork.article);

				this.dataFiltered = this.data.slice(0, this.limitCards);
				this.isLoading = false;
			}
		} catch (error) {
			console.error('Error fetching data:', error);
			this.hasError = true;
		}
	},
	updateDateLocale(array) {
		if (array && array.length > 0) {
			array.map((item) => {
				if (this.lang === 'fr') {
					item.publicationDate = item.publicationDateFrench;
					console.log('item.publicationDateFrench;:', item.publicationDateFrench);
					console.log('item.publicationDate:', item.publicationDate);
				}
			});
		}
	},
	/**
	 * Filters the categories from the data.
	 *
	 * It uses flatMap to create an array of all the categories from the data.
	 * Then it uses a Map to get the unique categories.
	 * Finally, it sets the `categories` property with the unique categories.
	 */
	getCategories() {
		console.log('#getCategories:');
		const categories = this.data.flatMap((item) => item.categoriesWorkTypes);
		const uniqueCategories = Array.from(new Map(categories.map((category) => [category.id, category])).values());

		this.categories = uniqueCategories;
	},
	/**
	 * Filters the data based on the category and search term.
	 *
	 * It filters the data based on the selected category.
	 * If the search term is not empty, it filters the result based on the search term.
	 * If `showAll` is false, it limits the result to `limitCards`.
	 * Finally, it sets the `dataFiltered` property with the filtered result.
	 */
	searchFilter() {
		let result = [];

		result = this.data.filter((item) => item.categoriesWorkTypes[0].id.includes(this.selectedCategory));
		console.log('result #1:', result);

		if (this.searchTerm !== '') {
			result = result.filter((item) => {
				console.log('this.searchTerm.:', this.searchTerm);
				console.log('item.title:', item.title);
				return item.title.toLowerCase().includes(this.searchTerm.toLowerCase());
			});
		}

		if (!this.showAll) {
			result = result.slice(0, this.limitCards);
			console.log('slice:', result);
			this.dataFiltered = result;
		} else {
			this.dataFiltered = result;
		}
	},
	/**
	 * Calls searchFilter with the current selectedCategory when the searchTerm changes.
	 *
	 * This is a watcher function for the searchTerm property. It is called whenever
	 * the searchTerm property changes. It calls searchFilter with the current
	 * selectedCategory to filter the data by the new search term.
	 */
	searchTermCallback() {
		this.searchFilter();
		this.updateUrl();
	},
	/**
	 * Calls the searchFilter function with the current selectedCategory when the value is true.
	 */
	showAllCards(value) {
		if (value) {
			this.searchFilter();
			this.updateUrl();
		}
	}
});
