export const graphqlEndpoint = '/api';

export const queryWorksFeature = `
query worksEntries($siteHandle: [String]) {
	entries(section: "pages", limit: 1 type: "landingWorks", orderBy: "dateCreated DESC", site: $siteHandle) {
		... on landingWorks_Entry {
			article: featureWork {
				dateCreated @formatDateTime(format: "Y-m-d")
				id
				url
				title
				... on singleWorks_Entry {
					publicationDate: date @formatDateTime (format: "F j, Y")
					publicationDateEnglish: date @formatDateTime(format: "F j, Y", locale: "en")
					publicationDateFrench: date @formatDateTime(format: "d F Y", locale: "fr")
					featureImageDesktop: featureImage {
						url @transform(width: 700, height: 400, mode: "crop")
						title
					}
					featureImage {
						url @transform(width: 700, height: 700, mode: "crop")
						title
					}
					categoriesWorkTypes {
						title
						id
						... on workTypes_Category {
							color: workTypesColor
						}
					}
				}
			}
		}
	}
}
`;

export const queryWorksEntries = `
query worksEntries($siteHandle: [String]) {
	entries (section: "works", orderBy: "date DESC", site: $siteHandle) {
		dateCreated @formatDateTime (format: "Y-m-d")
		id
		url
		title
		... on singleWorks_Entry {
			publicationDate: date @formatDateTime (format: "F j, Y")
			publicationDateEnglish: date @formatDateTime(format: "F j, Y", locale: "en")
			publicationDateFrench: date @formatDateTime(format: "d F Y", locale: "fr")
			featureImage {
				url @transform(width: 700, height: 700, mode: "crop")
				title
			}
			categoriesWorkTypes {
				title
				id
				... on workTypes_Category {
					color: workTypesColor
				}
			}
		}
	}
}
`;
